export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "farmingPageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farming"])},
        "freeStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Stake"])},
        "lockStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock Stake"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
        "farmingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO"])},
        "stakeEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake NECO, Earn NECO"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
        "totalStaked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Staked"])},
        "myStaked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Staked"])},
        "dayDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Distribution"])},
        "claimable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claimable"])},
        "halvingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halving Time"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim"])},
        "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["input amount"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
        "token-farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Stake"])},
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake"])},
        "unstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unstake"])},
        "availableToStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Amount you are willing to stake"])},
        "availableToUnStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Amount you are willing to unstake"])},
        "withdrawHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can withdraw what you staked any moment"])},
        "nothingDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no finished stake & farming pool."])}
      },
      "zh": {
        "farmingPageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币农场"])},
        "freeStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由质押"])},
        "lockStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁仓质押"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
        "farmingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO"])},
        "stakeEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押 NECO, 获得 NECO"])},
        "totalStaked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总质押"])},
        "myStaked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的质押"])},
        "dayDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天分发量"])},
        "claimable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可收割"])},
        "halvingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["减半时间"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收割"])},
        "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入数量"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总量"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
        "token-farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币质押"])},
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押"])},
        "unstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赎回"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准合约"])},
        "availableToStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入要质押的数量"])},
        "availableToUnStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入要提取的数量"])},
        "withdrawHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在任何时候都可以将你的质押赎回"])},
        "nothingDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无已完成的质押挖矿池子."])}
      }
    }
  })
}
