enum FarmDialogType {
  stake = 'stake',
  unstake = 'unstake',
}

export interface StakeDialogData {
  amount: string;
  isMax: boolean
}

export default FarmDialogType;
