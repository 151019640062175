
import {
  defineComponent, onMounted, reactive, ref, watch,
} from 'vue';
import moment from 'moment';

import StakeDialog from '@/components/Farms/StakeDialog.vue';
import { TokenFarmPool } from '@/configs/farming/token-farm-pool';
import { useStore } from '@/store';
import {
  calculateApy, claim, getClaimedOfAccount,
  getStakedOfAccount,
  getTotalStaked, halvingTime, rewardRate, stakeToken, withdraw,
} from '@/common/contracts/apis/neco-farming-api';
import Tools from '@/common/tools';
import { approveToken, getAllowanceBalance, getBalance } from '@/common/contracts/apis/erc20-api';
import { StakeDialogData } from '@/types/token-farm';

export default defineComponent({
  props: {
    farm: {
      type: TokenFarmPool,
      required: true,
    },
  },

  components: { StakeDialog },

  setup(props) {
    const store = useStore();

    const stakeDialogVisible = ref(false);
    const withdrawDialogVisible = ref(false);
    const data = reactive({
      totalStaked: '0',
      myStaked: '0',
      myStakedOriginal: '0',
      claimable: '0',
      claimableOriginal: '0',
      halvingTime: '0',
      tokenAmount: '0',
      tokenAmountOriginal: '0',
      apy: '0',
      dailyDistribution: '0',
      approvedAmount: '0',
    });

    const fetchTotalStaked = () => {
      if (store.state.web3 !== null) {
        getTotalStaked(store.state.web3, props.farm.poolAddress)
          .then((result) => {
            data.totalStaked = Tools.truncateDecimals(result);
          });
      }
    };

    const fetchMyStaked = () => {
      if (store.state.web3 !== null && store.state.currentAccount !== undefined) {
        getStakedOfAccount(
          store.state.web3, props.farm.poolAddress, store.state.currentAccount,
        ).then((result) => {
          data.myStakedOriginal = result;
          data.myStaked = Tools.truncateDecimals(result);
        });
      }
    };

    const fetchTokenBalance = () => {
      if (store.state.web3 !== null && store.state.currentAccount !== undefined) {
        getBalance(
          store.state.web3, props.farm.stakedTokenAddress, store.state.currentAccount,
        ).then((result) => {
          data.tokenAmountOriginal = result;
          data.tokenAmount = Tools.truncateDecimals(result);
        });
      }
    };

    const fetchAPY = () => {
      if (store.state.web3 !== null) {
        calculateApy(
          store.state.web3,
          props.farm.poolAddress,
          props.farm.isLPFarming,
          props.farm.stakedTokenAddress,
        ).then((result) => {
          data.apy = result;
        });
      }
    };

    const fetchApprovedAmount = () => {
      if (store.state.web3 !== null && store.state.currentAccount !== undefined) {
        getAllowanceBalance(
          store.state.web3,
          props.farm.stakedTokenAddress,
          store.state.currentAccount,
          props.farm.poolAddress,
        ).then((result) => {
          data.approvedAmount = Tools.truncateDecimals(result);
        });
      }
    };

    const fetchHalvingTime = () => {
      if (store.state.web3 !== null) {
        halvingTime(store.state.web3, props.farm.poolAddress).then((result) => {
          const time = moment(result * 1000).format(
            'YYYY-MM-DD HH:mm:ss',
          );
          data.halvingTime = `${time}`;
        });
      }
    };

    const fetchClaimable = () => {
      if (store.state.web3 !== null && store.state.currentAccount !== undefined) {
        getClaimedOfAccount(
          store.state.web3, props.farm.poolAddress, store.state.currentAccount,
        ).then((result) => {
          data.claimableOriginal = result;
          data.claimable = Tools.truncateDecimals(result);
        });
      }
    };

    const fetchDailyDistribute = () => {
      if (store.state.web3 !== null) {
        rewardRate(
          store.state.web3, props.farm.poolAddress,
        ).then((result) => {
          data.dailyDistribution = Tools.truncateDecimals(`${Number(result) * 3600 * 24}`);
        });
      }
    };

    const refreshPage = () => {
      fetchTotalStaked();
      fetchMyStaked();
      fetchAPY();
      fetchHalvingTime();
      fetchClaimable();
      fetchDailyDistribute();
      fetchTokenBalance();
      fetchApprovedAmount();
    };

    const claimAll = () => {
      if (store.state.web3 !== null && store.state.currentAccount) {
        claim(
          store.state.web3,
          props.farm.poolAddress,
          store.state.currentAccount,
          data.claimableOriginal,
        ).then(() => {
          refreshPage();
        });
      }
    };

    const approve = () => {
      if (store.state.web3 !== null && store.state.currentAccount) {
        approveToken(
          store.state.web3,
          props.farm.stakedTokenAddress,
          store.state.currentAccount,
          props.farm.poolAddress,
          '9000000000000000000000000',
        ).then(() => {
          refreshPage();
        });
      }
    };

    const stake = ({ amount }: StakeDialogData) => {
      const stakeAmount = `${Math.floor(Number(amount) * 1000)}000000000000000`;
      console.log(stakeAmount);
      if (store.state.web3 !== null && store.state.currentAccount) {
        stakeToken(
          store.state.web3,
          props.farm.poolAddress,
          store.state.currentAccount,
          stakeAmount,
        ).then(() => {
          refreshPage();
        });
      }
    };

    const withdrawToken = ({ amount, isMax }: StakeDialogData) => {
      let withdrawAmount;
      console.log(isMax);
      if (!isMax) {
        withdrawAmount = `${Math.floor(Number(amount))}000000000000000000`;
      } else {
        withdrawAmount = amount;
      }

      console.log(withdrawAmount);
      if (store.state.web3 !== null && store.state.currentAccount) {
        withdraw(
          store.state.web3,
          props.farm.poolAddress,
          store.state.currentAccount,
          withdrawAmount,
        ).then(() => {
          refreshPage();
        });
      }
    };

    onMounted(() => {
      refreshPage();
    });

    watch([
      () => store.state.web3,
      () => store.state.currentAccount,
      () => store.state.currentChainId], () => {
      console.log('Farm store.state.web3', store.state.web3);
      refreshPage();
    });

    return {
      stakeDialogVisible,
      withdrawDialogVisible,
      data,
      claimAll,
      stake,
      withdrawToken,
      approve,
    };
  },
});

