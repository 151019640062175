import FarmPoolType from '@/configs/farming/farm-pool-type';
import { currentEnvironment, ProjectEnvironment } from '@/common/project-environment';

// eslint-disable-next-line
const necoIcon = require('../../assets/images/farms/token-farm/token-icon/NECO.png');
// eslint-disable-next-line
const bnbIcon = require('../../assets/images/farms/token-farm/token-icon/BNB.png');

class TokenFarmPool {
  // 编号
  public index: number;

  // Farming的标题
  public title: string;

  // 质押挖矿池子的类型
  public farmPoolType: FarmPoolType;

  public isLPFarming: boolean;

  public token1Symbol: string;

  // Token1的图片连接
  public token1ImgPath: string;

  public token2Symbol: string;

  // Token2的图片连接
  public token2ImgPath: string;

  public stakedTokenAddress: string;

  // 质押挖矿池子的地址
  public poolAddress: string;

  // 可获取的token的symbol
  public earnTokenSymbol: string

  public tokenIcon: string

  public originTokenIcon: string

  public constructor(
    index: number,
    title: string,
    isLPFarming: boolean,
    farmPoolType: FarmPoolType,
    token1Symbol: string,
    token1ImgPath: string,
    token2Symbol: string,
    token2ImgPath: string,
    stakedTokenAddress: string,
    poolAddress: string,
    earnTokenSymbol: string,
    originTokenIcon: string = necoIcon,
    tokenIcon: string = necoIcon,
  ) {
    this.index = index;
    this.title = title;
    this.isLPFarming = isLPFarming;
    this.farmPoolType = farmPoolType;
    this.token1Symbol = token1Symbol;
    this.token1ImgPath = token1ImgPath;
    this.token2Symbol = token2Symbol;
    this.token2ImgPath = token2ImgPath;
    this.stakedTokenAddress = stakedTokenAddress;
    this.poolAddress = poolAddress;
    this.earnTokenSymbol = earnTokenSymbol;
    this.tokenIcon = tokenIcon;
    this.originTokenIcon = originTokenIcon;
  }
}

const tokenFarmPools = [
  new TokenFarmPool(
    1,
    'Stake NECO',
    false,
    FarmPoolType.TokenFarming,
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x8B5a4a4F3abdd03cb241A5C09F032051656b68E1',
    'NECO',
  ),
  new TokenFarmPool(
    2,
    'Stake BNB&NECO',
    true,
    FarmPoolType.TokenFarming,
    'BNB',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    '0xd91b0CA001B014C898E0564F4450b0269A730fC1',
    '0xD2CB19C09417DA49658e3Fd0196aDfa350780277',
    'NECO',
    bnbIcon,
    necoIcon,
  ),
];

const tokenFarmPoolsDev = [
  new TokenFarmPool(
    1,
    'FREE STAKE',
    false,
    FarmPoolType.TokenFarming,
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    '0xafA98d54481a9aE468AB21b9268609fF50795795',
    '0x9fb8cf6a2980fc67834d5dafc56834311fd89bc4',
    'NECO',
  ),
  new TokenFarmPool(
    2,
    'FREE STAKE',
    true,
    FarmPoolType.TokenFarming,
    'BNB',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    '0x3180267e65b6FdDDD32B04442E7357c9e93a533f',
    '0x4daeab8fc6bda32210a5075ee6253524fbc41b8b',
    'NECO',
    bnbIcon,
    necoIcon,
  ),
  new TokenFarmPool(
    2,
    'FREE STAKE',
    true,
    FarmPoolType.TokenFarming,
    'BNB',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    'NECO',
    'https://drive.google.com/file/d/1YarIztVRUbk-GMZZUfP3izZZRJc_Xac6/view',
    '0x3180267e65b6FdDDD32B04442E7357c9e93a533f',
    '0x4daeab8fc6bda32210a5075ee6253524fbc41b8b',
    'NECO',
    bnbIcon,
    necoIcon,
  ),
];

const tokenFarmPoolList = currentEnvironment() === ProjectEnvironment.Production
  ? tokenFarmPools : tokenFarmPoolsDev;

const doneFarmPoolList: Array<TokenFarmPool> = [];

export {
  TokenFarmPool,
  tokenFarmPools,
  tokenFarmPoolsDev,
  tokenFarmPoolList,
  doneFarmPoolList,
};
